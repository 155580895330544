<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'alunos' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Historico acadêmico -
          <span class="text-h5">
            {{ aluno.nomecompleto ? aluno.nomecompleto : " " }}
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <!-- INICIO MOVIMENTAÇÃO DE MATRICULA ANO ATUAL -->
      <v-container>
        <template>
          <v-row>
            <v-col class="d-flex justify-start mb-3">
              <v-btn @click="() => baixarHistorico()" color="yellow darken-4 white--text">
                Baixar histórico acadêmico
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>MOVIMENTAÇÕES DE MATRÍCULA DO ANO LETIVO ATUAL</strong>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(matricula, index) in historicoMatriculaAnoAtual"
                  :key="matricula.id"
                >
                  <v-expansion-panel-header :class="{ 'highlighted-panel': isHighlighted }">
                    <strong>Matrícula {{ matricula.id }}</strong>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content :class="{ 'highlighted-panel': isHighlighted }">
                    <div>Ano: {{ matricula.ano.descricao }}</div>
                    <div>Série: {{ matricula.serie.descricao }}</div>
                    <div>Turma: {{ matricula.turma?.descricao }}</div>
                    <div>Instituição de Ensino: {{ matricula.turma?.franquia.descricao }}</div>
                    <div>CNPJ: {{ matricula.turma?.franquia.cnpj }}</div>
                    <div>Identificação única (INEP): {{ matricula.aluno.codigo_inep }}</div>
                    <div>Município: {{ matricula.aluno.municipalidade }}</div>
                    <div>Estado: {{ matricula.aluno.estadualidade }}</div>
                    <!-- Campos editáveis -->
                    <v-form>
                      <!-- Número da Escola -->
                      <v-row>
                        <v-col class="pt-7 pb-0" cols="6">
                          <label>Nº da escola</label>
                          <e-autocomplete
                            :items="$constants.numeroEscola"
                            :return-object="false"
                            :item-text="(item) => item.descricao"
                            :item-value="(item) => item.id"
                            v-model="matricula.numero_escola"
                            solo
                            dense
                            @blur="salvarMovimentacaoMatriculaInterna(matricula)"
                          />
                        </v-col>
                      </v-row>

                      <!-- Estudante de Busca Ativa -->
                      <v-switch
                        v-model="matricula.busca_ativa"
                        label="É estudante do busca ativa?"
                        @change="salvarMovimentacaoMatriculaInterna(matricula)"
                      >
                        ></v-switch
                      >

                      <!-- Estudante novo ou repetente -->
                      <div>É estudante novo ou repetente nesta escola, neste ano letivo?</div>
                      <v-radio-group
                        v-model="matricula.estudante_escola"
                        @change="salvarMovimentacaoMatriculaInterna(matricula)"
                      >
                        <v-radio label="N (Novo)" value="N"></v-radio>
                        <v-radio label="R (Repetente)" value="R"></v-radio>
                        <v-radio label="Outra Situação" value="Outra"></v-radio>
                      </v-radio-group>

                      <!-- Transferência -->
                      <v-checkbox
                        v-model="matricula.transferencia_ano"
                        label="Houve transferência do(a) estudante dentro do ano letivo?"
                        @change="salvarMovimentacaoMatriculaInterna(matricula)"
                      ></v-checkbox>

                      <!-- Se transferência -->
                      <v-row v-if="matricula.transferencia_ano">
                        <v-checkbox
                          v-model="matricula.transferencias"
                          label="Sim, 1 vez"
                          value="1"
                          @change="salvarMovimentacaoMatriculaInterna(matricula)"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="matricula.transferencias"
                          label="Sim, 2 vezes"
                          value="2"
                          @change="salvarMovimentacaoMatriculaInterna(matricula)"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="matricula.transferencias"
                          label="Sim, 3 vezes"
                          value="3"
                          @change="salvarMovimentacaoMatriculaInterna(matricula)"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="matricula.transferencias"
                          label="Sim, 4 vezes"
                          value="4"
                          @change="salvarMovimentacaoMatriculaInterna(matricula)"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="matricula.transferencias"
                          label="Sim, 5 vezes"
                          value="5"
                          @change="salvarMovimentacaoMatriculaInterna(matricula)"
                        ></v-checkbox>
                      </v-row>

                      <!-- Observações Complementares -->
                      <v-row>
                        <v-col class="pt-7 pb-0" cols="12">
                          <label>1º Observações Complementares</label>
                          <v-card class="pa-md-4">
                            <v-textarea
                              v-model="matricula.observacoes"
                              placeholder="Digite aqui"
                              rows="3"
                              class="px-4"
                              @change="salvarMovimentacaoMatriculaInterna(matricula)"
                            ></v-textarea>
                          </v-card>
                        </v-col>
                      </v-row>

                      <!-- Situação Final do Estudante -->
                      <v-row>
                        <v-col class="pt-7 pb-0" cols="6">
                          <label> Situação final do(a) estudante na escola: </label>
                          <e-autocomplete
                            v-model="matricula.situacao_estudante"
                            :items="$constants.situacoesMatricula"
                            label="Informe a Situação"
                            @change="salvarMovimentacaoMatriculaInterna(matricula)"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="primary"
                                v-on="on"
                                @click="adicionarMovimentacaoMatricula(matricula)"
                              >
                                Adicionar Movimentação
                              </v-btn>
                            </template>
                            <span
                              >Adicionar movimentação quando o aluno se transferir para outro
                              município.</span
                            >
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-expansion-panels>
                            <v-expansion-panel
                              v-for="(movimentacao, index) in movimentacoesExternas.filter(
                                (mov) => mov.matricula_id === matricula.id
                              )"
                              :key="index"
                            >
                              <v-expansion-panel-header
                                :class="{ 'highlighted-panel': isHighlighted }"
                              >
                                <strong>MOVIMENTAÇÃO {{ index + 1 }} </strong>
                              </v-expansion-panel-header>

                              <v-expansion-panel-content
                                :class="{ 'highlighted-panel': isHighlighted }"
                              >
                                <ValidationObserver v-slot="{ handleSubmit }">
                                  <form
                                    @submit.prevent="handleSubmit(salvarMovimentacaoAlunoExterno)"
                                    :disabled="submittingForm"
                                  >
                                    <!-- Linha 1: Ano, Série -->
                                    <v-row>
                                      <v-col cols="6">
                                        <ValidationProvider
                                          name="N° Escola"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <e-label>N° Escola</e-label>
                                          <e-autocomplete
                                            :items="$constants.numeroEscola"
                                            :return-object="false"
                                            :error-messages="errors"
                                            :item-text="(item) => item.descricao"
                                            :item-value="(item) => item.id"
                                            v-model="movimentacao.numero_escola"
                                            dense
                                            solo
                                          />
                                        </ValidationProvider>
                                      </v-col>
                                      <v-col cols="6">
                                        <e-label>Ano</e-label>
                                        <e-autocomplete
                                          :items="filteredAnosList"
                                          :loading="carregandoSeries"
                                          :item-text="(ano) => ano.descricao"
                                          :return-object="false"
                                          :item-value="(ano) => ano.id"
                                          v-model="movimentacao.ano"
                                          label="Selecione uma opção"
                                          dense
                                          solo
                                        />
                                      </v-col>
                                      <v-col cols="6">
                                        <e-label>Série</e-label>
                                        <e-autocomplete
                                          :items="seriesList.filter((serie) => serie.situacao)"
                                          :loading="carregandoSeries"
                                          :item-text="(serie) => serie.descricao"
                                          :return-object="false"
                                          :item-value="(serie) => serie.id"
                                          v-model="movimentacao.serie"
                                          label="Selecione uma opção"
                                          dense
                                          solo
                                        />
                                      </v-col>
                                      <v-col cols="6">
                                        <ValidationProvider
                                          name="INEP"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <e-label>Identificação única(INEP)</e-label>
                                          <v-text-field
                                            :error-messages="errors"
                                            v-model="movimentacao.inep"
                                            dense
                                            solo
                                          >
                                          </v-text-field>
                                        </ValidationProvider>
                                      </v-col>
                                    </v-row>

                                    <!-- Linha 2: INEP, Município -->
                                    <v-row>
                                      <v-col cols="6">
                                        <e-label>Estado</e-label>
                                        <e-autocomplete
                                          :items="$constants.estadosList"
                                          :return-object="false"
                                          :item-text="(item) => item.codigo"
                                          :item-value="(item) => item.codigo"
                                          v-model="movimentacao.estado"
                                          label="Selecione uma opção"
                                          dense
                                          solo
                                          @change="(event) => atualizarEstado(event)"
                                        />
                                      </v-col>

                                      <v-col cols="6">
                                        <e-label>Município</e-label>
                                        <e-autocomplete
                                          :items="municipiosNasc"
                                          :return-object="false"
                                          :item-text="(item) => item"
                                          :item-value="(item) => item"
                                          v-model="movimentacao.municipio"
                                          label="Selecione uma opção"
                                          dense
                                          solo
                                        />
                                      </v-col>
                                    </v-row>

                                    <!-- Linha 3: Número da Escola, Instituição de Ensino -->
                                    <v-row>
                                      <v-col cols="6">
                                        <e-label>Instituição de Ensino</e-label>
                                        <e-autocomplete
                                          :items="franquiasList"
                                          v-model="movimentacao.configuracao_id"
                                          :item-value="(franquia) => franquia.id"
                                          :item-text="(franquia) => franquia.descricao"
                                          label="Selecione uma opção"
                                          dense
                                          solo
                                        />
                                      </v-col>
                                      <v-col cols="6">
                                        <e-label>CNPJ</e-label>
                                        <v-text-field
                                          dense
                                          solo
                                          readonly
                                          :value="getCnpj(movimentacao.configuracao_id)"
                                        />
                                      </v-col>
                                    </v-row>

                                    <!-- Linha 4: Estado, Transferência e Busca Ativa -->

                                    <!-- Linha 5: Estudante Novo ou Repetente, Transferência -->
                                    <v-row>
                                      <v-col cols="4">
                                        <v-radio-group
                                          v-model="movimentacao.estudante_novo_repetente"
                                          label="É estudante novo ou repetente nesta escola, neste ano letivo?"
                                        >
                                          <v-radio label="N (Novo)" value="N"></v-radio>
                                          <v-radio label="R (Repetente)" value="R"></v-radio>
                                          <v-radio label="Outra Situação" value="Outra"></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                      <v-col cols="4">
                                        <v-checkbox
                                          v-model="movimentacao.transferencia_ano"
                                          label="Houve transferência do(a) estudante dentro do ano letivo?"
                                        ></v-checkbox>

                                        <!-- Se transferência -->
                                        <v-row v-if="movimentacao.transferencia_ano">
                                          <v-checkbox
                                            v-model="movimentacao.transferencias"
                                            label="Sim, 1 vez"
                                            value="1"
                                          ></v-checkbox>
                                          <v-checkbox
                                            v-model="movimentacao.transferencias"
                                            label="Sim, 2 vezes"
                                            value="2"
                                          ></v-checkbox>
                                          <v-checkbox
                                            v-model="movimentacao.transferencias"
                                            label="Sim, 3 vezes"
                                            value="3"
                                          ></v-checkbox>
                                          <v-checkbox
                                            v-model="movimentacao.transferencias"
                                            label="Sim, 4 vezes"
                                            value="4"
                                          ></v-checkbox>
                                          <v-checkbox
                                            v-model="movimentacao.transferencias"
                                            label="Sim, 5 vezes"
                                            value="5"
                                          ></v-checkbox>
                                        </v-row>
                                      </v-col>
                                      <v-col cols="4">
                                        <v-switch
                                          v-model="movimentacao.busca_ativa"
                                          label="É estudante do busca ativa?"
                                        ></v-switch>
                                      </v-col>
                                    </v-row>

                                    <!-- Observações -->
                                    <v-row>
                                      <v-col cols="12">
                                        <label for=""
                                          >{{ index + 2 }}° Observações Complementares</label
                                        >
                                        <v-card class="pa-md-4">
                                          <v-textarea
                                            v-model="movimentacao.observacoes"
                                            placeholder="Digite aqui"
                                            rows="3"
                                            label="Se precisar digite outras opções"
                                            class="px-4"
                                          ></v-textarea>
                                        </v-card>
                                      </v-col>
                                    </v-row>

                                    <!-- Situação Final -->
                                    <v-row>
                                      <v-col class="pt-0 pb-0" cols="6">
                                        <ValidationProvider
                                          name="Situação final do(a) estudante na escola"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <e-label
                                            >Situação final do(a) estudante na escola</e-label
                                          >
                                          <v-select
                                            :items="$constants.situacoesMatricula"
                                            v-model="movimentacao.situacao_final"
                                            :error-messages="errors"
                                            label="Selecione uma situação"
                                            dense
                                            solo
                                          />
                                        </ValidationProvider>
                                      </v-col>
                                      <v-col class="pt-7 pb-0" cols="6">
                                        <v-btn
                                          color="yellow darken-4 white--text"
                                          type="submit"
                                          block
                                          @click="salvarMovimentacaoAlunoExterno(movimentacao)"
                                        >
                                          Salvar
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </form>
                                </ValidationObserver>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <!-- FINAL MOVIMENTAÇÃO DE MATRICULA ANO ATUAL -->

      <!-- INICIO MOVIMENTAÇÃO DE HISTORICO -->
      <v-container>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>MOVIMENTAÇÃO DE HISTÓRICO ACADÊMICO</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(matricula, index) in historicoMatriculaGeral"
                  :key="matricula.matricula_id"
                >
                  <v-expansion-panel-header :class="{ 'highlighted-panel': isHighlighted }">
                    <strong
                      >{{ matricula.ano ? matricula.ano?.descricao : "NÃO INFORMADO" }} / Série:
                      {{ matricula.serie ? matricula.serie?.descricao : "NÃO INFORMADO" }} /
                      {{ matricula.turma ? matricula.turma?.descricao : "NÃO INFORMADO" }}</strong
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content :class="{ 'highlighted-panel': isHighlighted }">
                    <div>Ano: {{ matricula.ano.descricao }}</div>
                    <div>
                      Série: {{ matricula.serie ? matricula.serie?.descricao : "NÃO INFORMADO" }}
                    </div>
                    <div>Instituição de Ensino: {{ matricula.turma?.franquia.descricao }}</div>
                    <div>Identificação única (INEP): {{ matricula.aluno.codigo_inep }}</div>
                    <div>Município: {{ matricula.aluno.municipalidade }}</div>
                    <div>Estado: {{ matricula.aluno.estadualidade }}</div>
                    <!-- Campos editáveis -->
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form
                        @submit.prevent="handleSubmit(salvarHistoricoInterno)"
                        :disabled="submittingForm"
                      >
                        <v-switch v-model="matricula.educacao_infantil" label="EDUCAÇÃO INFANTIL?">
                          ></v-switch
                        >
                        <v-switch v-model="matricula.oculta_ano_letivo" label="OCULTAR ANO LETIVO?">
                          ></v-switch
                        >
                        <template
                          v-if="!matricula.educacao_infantil && !matricula.oculta_ano_letivo"
                        >
                          <template v-if="!matricula.educacao_infantil">
                            <template>
                              <div>
                                <template>
                                  <div>
                                    <template v-if="matricula.notas && matricula.notas.length">
                                      <v-row
                                        v-for="(nota, notaIndex) in obterNotasUnicas(
                                          matricula.notas
                                        )"
                                        :key="notaIndex"
                                        class="mb-4"
                                      >
                                        <v-col cols="4">
                                          <v-select
                                            v-model="nota.disciplina_id"
                                            :items="disciplinasList"
                                            :item-value="(item) => item.id"
                                            :item-text="(item) => item.descricao"
                                            label="Escolha a disciplina"
                                            required
                                            :readonly="matricula.turma.ano.descricao > 2023"
                                          ></v-select>
                                        </v-col>

                                        <v-col cols="3">
                                          <v-text-field
                                            v-model="nota.valor"
                                            label="Inserir nota"
                                            type="number"
                                            required
                                            :readonly="matricula.turma.ano.descricao > 2023"
                                            step="0.01"
                                            min="0"
                                            max="10"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="1">
                                          <v-btn icon @click="removerDisciplina(index, notaIndex)">
                                            <v-icon color="red">mdi-close-circle</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col class="pt-7 pb-0" cols="6">
                                          <v-btn
                                            color="success"
                                            @click="adicionarDisciplinaHistoricoInterno(matricula)"
                                          >
                                            Adicionar Disciplina
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </template>
                                    <template
                                      v-else-if="!matricula.movimentacao_historico_interno[0]"
                                    >
                                      <v-row
                                        v-for="(nota, notaIndex) in obterNotasUnicas(
                                          matricula.notasCircuito
                                        )"
                                        :key="notaIndex"
                                        class="mb-4"
                                      >
                                        <v-col cols="4">
                                          <v-select
                                            v-model="nota.disciplina"
                                            :items="disciplinasList"
                                            :item-value="(item) => item.id"
                                            :item-text="(item) => item.descricao"
                                            label="Disciplina"
                                            required
                                            :readonly="matricula.turma.ano.descricao > 2023"
                                          ></v-select>
                                        </v-col>

                                        <v-col cols="3">
                                          <v-text-field
                                            v-model="nota.valor"
                                            label="Inserir nota"
                                            type="number"
                                            required
                                            :readonly="matricula.turma.ano.descricao > 2023"
                                            step="0.01"
                                            min="0"
                                            max="10"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="1">
                                          <v-btn icon @click="removerDisciplina(index, notaIndex)">
                                            <v-icon color="red">mdi-close-circle</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col class="pt-7 pb-0" cols="6">
                                          <v-btn
                                            color="success"
                                            @click="adicionarDisciplinaHistoricoInterno"
                                          >
                                            Adicionar Disciplina
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </template>
                                    <template v-else>
                                      <v-row
                                        v-for="(nota, notaIndex) in obterNotasUnicas(
                                          matricula.notas
                                        )"
                                        :key="notaIndex"
                                        class="mb-4"
                                      >
                                        <v-col cols="4">
                                          <v-select
                                            v-model="nota.disciplina_id"
                                            :items="disciplinasList"
                                            :item-value="(item) => item.id"
                                            :item-text="(item) => item.descricao"
                                            label="Escolha a disciplina"
                                            required
                                            :readonly="matricula.turma.ano.descricao > 2023"
                                          ></v-select>
                                        </v-col>

                                        <v-col cols="3">
                                          <v-text-field
                                            v-model="nota.valor"
                                            label="Inserir nota"
                                            type="number"
                                            required
                                            :readonly="matricula.turma.ano.descricao > 2023"
                                            step="0.01"
                                            min="0"
                                            max="10"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col cols="1">
                                          <v-btn icon @click="removerDisciplina(index, notaIndex)">
                                            <v-icon color="red">mdi-close-circle</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col class="pt-7 pb-0" cols="6">
                                          <v-btn
                                            color="success"
                                            @click="adicionarDisciplinaHistoricoInterno(matricula)"
                                          >
                                            Adicionar Disciplina
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </div>
                                </template>
                              </div>
                            </template>
                          </template>
                          <template>
                            <v-row
                              v-for="(disciplina, dIndex) in disciplinasInternas"
                              :key="dIndex"
                              class="align-center"
                            >
                              <v-col class="pt-7 pb-0" cols="5">
                                <e-label>Disciplina</e-label>
                                <e-autocomplete
                                  v-model="disciplina.disciplina_id"
                                  :items="disciplinasList"
                                  :item-value="(item) => item.id"
                                  :item-text="(item) => item.descricao"
                                  label="Escolha a disciplina"
                                />
                              </v-col>

                              <v-col class="pt-7 pb-0" cols="3">
                                <e-label>RF</e-label>
                                <v-text-field
                                  v-model="disciplina.valor"
                                  label="Inserir nota"
                                  type="number"
                                  step="0.01"
                                  min="0"
                                  max="10"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46"
                                  solo
                                  dense
                                />
                              </v-col>

                              <v-col cols="2">
                                <v-btn icon color="red" @click="removerDisciplinaInterna(dIndex)">
                                  <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                        <template v-if="!matricula.oculta_ano_letivo">
                          <v-row>
                            <v-col class="pt-7 pb-0" cols="5">
                              <e-label>Situação final do(a) estudante na escola:</e-label>
                              <e-autocomplete
                                v-model="matricula.situacao_estudante"
                                :items="$constants.situacoesMatricula"
                                label="Informe a Situação"
                              />
                            </v-col>
                          </v-row>
                          <!-- Observações Complementares -->
                          <v-row>
                            <v-col class="pt-7 pb-7" cols="12">
                              <label>1° Observações Complementares</label>
                              <v-card class="pa-md-4">
                                <v-textarea
                                  v-model="matricula.observacoes"
                                  placeholder="Digite aqui"
                                  rows="3"
                                  class="px-4"
                                ></v-textarea>
                              </v-card>
                            </v-col>
                          </v-row>
                        </template>

                        <v-row>
                          <v-col cols="6">
                            <v-btn
                              color="yellow darken-4 white--text"
                              type="submit"
                              block
                              @click="salvarHistoricoInterno(matricula)"
                            >
                              Salvar
                            </v-btn>
                          </v-col>
                          <v-col cols="6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  color="primary"
                                  v-on="on"
                                  @click="adicionarMovimentacaoHistorico(matricula)"
                                >
                                  Adicionar Movimentação
                                </v-btn>
                              </template>
                              <span
                                >Adicionar movimentação quando o aluno se transferir para outro
                                município.</span
                              >
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-expansion-panels>
                              <v-expansion-panel
                                v-for="(movimentacao, index) in movimentacoesHistorico.filter(
                                  (mov) => mov.matricula_id === matricula.id
                                )"
                                :key="index"
                              >
                                <v-expansion-panel-header
                                  :class="{ 'highlighted-panel': isHighlighted }"
                                >
                                  <strong>MOVIMENTAÇÃO {{ index + 1 }} </strong>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content
                                  :class="{ 'highlighted-panel': isHighlighted }"
                                >
                                  <ValidationObserver v-slot="{ handleSubmit }">
                                    <form
                                      @submit.prevent="
                                        handleSubmit(salvarMovimentacaoHistoricoExterno)
                                      "
                                      :disabled="submittingForm"
                                    >
                                      <!-- Linha 1: Ano, Série -->
                                      <v-row>
                                        <v-col cols="6">
                                          <e-label>Ano</e-label>
                                          <e-autocomplete
                                            :items="filteredAnosList"
                                            :loading="carregandoSeries"
                                            :item-text="(ano) => ano.descricao"
                                            :return-object="false"
                                            :item-value="(ano) => ano.id"
                                            v-model="movimentacao.ano"
                                            label="Selecione uma opção"
                                            dense
                                            solo
                                          />
                                        </v-col>
                                        <v-col cols="6">
                                          <ValidationProvider
                                            name="Série"
                                            rules="required"
                                            v-slot="{ errors }"
                                          >
                                            <e-label>Série</e-label>
                                            <e-autocomplete
                                              :items="seriesList.filter((serie) => serie.situacao)"
                                              :loading="carregandoSeries"
                                              :item-text="(serie) => serie.descricao"
                                              :return-object="false"
                                              :item-value="(serie) => serie.id"
                                              v-model="movimentacao.serie"
                                              label="Selecione uma opção"
                                              dense
                                              solo
                                              :error-messages="errors"
                                            />
                                          </ValidationProvider>
                                        </v-col>
                                      </v-row>

                                      <!-- Linha 2: INEP, Município -->
                                      <v-row>
                                        <v-col cols="6">
                                          <e-label>Identificação única(INEP)</e-label>
                                          <v-text-field v-model="movimentacao.inep" dense solo>
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                          <e-label>Instituição de Ensino</e-label>
                                          <v-text-field
                                            v-model="movimentacao.instituicao_ensino"
                                            dense
                                            solo
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>

                                      <!-- Linha 3: Número da Escola, Instituição de Ensino -->
                                      <v-row>
                                        <v-col cols="6">
                                          <e-label>Estado</e-label>
                                          <e-autocomplete
                                            :items="$constants.estadosList"
                                            :return-object="false"
                                            :item-text="(item) => item.codigo"
                                            :item-value="(item) => item.codigo"
                                            v-model="movimentacao.estado"
                                            label="Selecione uma opção"
                                            dense
                                            solo
                                            @change="(event) => atualizarEstado(event)"
                                          />
                                        </v-col>
                                        <v-col cols="6">
                                          <e-label>Município</e-label>
                                          <e-autocomplete
                                            :items="municipiosNasc"
                                            :return-object="false"
                                            :item-text="(item) => item"
                                            :item-value="(item) => item"
                                            v-model="movimentacao.municipio"
                                            label="Selecione uma opção"
                                            dense
                                            solo
                                          />
                                        </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col class="pt-7 pb-0" cols="6">
                                          <v-btn
                                            color="success"
                                            @click="adicionarDisciplinaHistoricoExterno(index)"
                                            >Adicionar Disciplina</v-btn
                                          >
                                        </v-col>
                                      </v-row>
                                      <template>
                                        <v-row
                                          v-for="(nota, notaIndex) in obterNotasUnicas(
                                            movimentacao.notas
                                          )"
                                          :key="notaIndex"
                                          class="mb-4"
                                        >
                                          <v-col cols="4">
                                            <v-select
                                              v-model="nota.disciplina_id"
                                              :items="disciplinasList"
                                              :item-value="(item) => item.id"
                                              :item-text="(item) => item.descricao"
                                              label="Escolha a disciplina"
                                              required
                                            ></v-select>
                                          </v-col>

                                          <v-col cols="3">
                                            <v-text-field
                                              v-model="nota.valor"
                                              label="Inserir nota"
                                              type="number"
                                              required
                                              step="0.01"
                                              min="0"
                                              max="10"
                                              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="1">
                                            <v-btn
                                              icon
                                              @click="
                                                removerDisciplinaMovimentacaoExterna(
                                                  notaIndex,
                                                  movimentacao
                                                )
                                              "
                                            >
                                              <v-icon color="red">mdi-close-circle</v-icon>
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </template>
                                      <template>
                                        <v-row
                                          v-for="(
                                            disciplina, dIndex
                                          ) in movimentacao.disciplinasExternas"
                                          :key="dIndex"
                                          class="align-center"
                                        >
                                          <v-col class="pt-7 pb-0" cols="5">
                                            <e-label>Disciplina</e-label>
                                            <e-autocomplete
                                              v-model="disciplina.disciplina"
                                              :items="disciplinasList"
                                              :item-value="(item) => item.id"
                                              :item-text="(item) => item.descricao"
                                              label="Escolha a disciplina"
                                            />
                                          </v-col>
                                          <v-col class="pt-7 pb-0" cols="3">
                                            <e-label>RF</e-label>
                                            <v-text-field
                                              v-model="disciplina.valor"
                                              label="Inserir nota"
                                              type="number"
                                              step="0.01"
                                              min="0"
                                              max="10"
                                              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46"
                                              solo
                                              dense
                                            />
                                          </v-col>

                                          <v-col cols="2">
                                            <v-btn
                                              icon
                                              color="red"
                                              @click="removerDisciplinaExterna(index, dIndex)"
                                            >
                                              <v-icon>mdi-close-circle</v-icon>
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </template>
                                      <!-- Observações -->
                                      <v-row>
                                        <v-col class="pt-7 pb-7" cols="12">
                                          <label>{{ index + 2 }}° Observações Complementares</label>
                                          <v-card class="pa-md-4">
                                            <v-textarea
                                              v-model="movimentacao.observacoes"
                                              placeholder="Digite aqui"
                                              rows="3"
                                              class="px-4"
                                            ></v-textarea>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <!-- Situação Final -->
                                      <v-row>
                                        <v-col class="pt-0 pb-0" cols="6">
                                          <e-label
                                            >Situação final do(a) estudante na escola</e-label
                                          >
                                          <v-select
                                            :items="$constants.situacoesMatricula"
                                            v-model="movimentacao.situacao_final"
                                            label="Selecione uma situação"
                                            dense
                                            solo
                                          />
                                        </v-col>
                                        <v-col class="pt-7 pb-0" cols="6">
                                          <v-btn
                                            color="yellow darken-4 white--text"
                                            type="submit"
                                            block
                                            @click="
                                              salvarMovimentacaoHistoricoExterno(movimentacao)
                                            "
                                          >
                                            Salvar
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </form>
                                  </ValidationObserver>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <!-- FINAL MOVIMENTAÇÃO DE HISTORICO -->
    </v-row>
  </main-template>
</template>

<script lang="js">
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      disciplinas: [],
      franquiasList: [],

      disciplinasExternas: [],
      disciplinasInternas: [],
      municipiosNasc: [],
      carregandoHistorico: false,
      carregandoSeries: false,
      submittingForm: false,
      dataTableFilter,
      movimentacoesAll: null,
      search: "",
      aluno: "",
      movimentacoes: [],
      movimentacoesHistorico: [],
      movimentacoesMatricula: [],
      movimentacoesExternas: [],
      historicoList: [],
      historicoMatriculaAnoAtual: [],
      historicoMatriculaGeral: [

      ],
      seriesList: [],
      anosList: [],
      disciplinasList: [],
      isHighlighted: true,
      observacoes: [
        { texto: '' },
      ],
    };
  },
  computed: {
    filteredAnosList() {
      // Gera os anos de 2000 a 2020 dinamicamente
      const anos2000a2020 = Array.from({ length: 21 }, (v, i) => {
        const year = 2000 + i;
        return { id: year, descricao: String(year), situacao: true };
      });

      // Combina os anos de 2000 a 2020 com os anos existentes
      return [...anos2000a2020, ...this.anosList];
    },
  },
  mounted() {
    this.carregarHistorico(this.$router.currentRoute.params.aluno_id);
    this.historicoMatriculaAnoAtual.forEach(matricula => {
      if (matricula.situacao === 'TRANSFERIDO') {
        matricula.transferencia_ano = true;
      }
    });
  },
  methods: {
    getCnpj(configuracaoId) {

      const franquiaSelecionada = this.franquiasList.find(
        (franquia) => franquia.id === configuracaoId
      );
      return franquiaSelecionada ? franquiaSelecionada.cnpj : "";
    },
    async atualizarEstado(value) {
      if (value) {
        this.municipiosNasc = this.$constants.municipios[value].cidades;
      }
    },
    adicionarDisciplina(matricula) {
      this.disciplinas.push({
        disciplina_id: '',
        valor: ''
      });
    },
    adicionarDisciplinaHistoricoExterno(index) {
      if (!this.movimentacoesHistorico[index].disciplinasExternas) {
        this.$set(this.movimentacoesHistorico[index], 'disciplinasExternas', []);
      }

      this.movimentacoesHistorico[index].disciplinasExternas.push({
        disciplina_id: null,
        valor: null
      });
    },
    adicionarDisciplinaHistoricoInterno(matricula) {
      this.disciplinasInternas.push({
        disciplina_id: '',
        valor: ''
      });
    },
    adicionarMovimentacaoMatricula(matricula) {
      const novaMovimentacao = {
        aluno_id: matricula.aluno_id,
        matricula_id: matricula.id,
        ano: '',
        serie: '',
        inep: '',
        municipio: '',
        numero_escola: '',
        instituicao_ensino: '',
        estado: '',
        estudante_novo_repetente: '',
        transferencia_ano: false,
        transferencias: '',
        busca_ativa: false,
        observacoes: '',
        situacao_final: '',
      };

      this.movimentacoesExternas.push(novaMovimentacao);
      this.salvarMovimentacaoAlunoExterno(novaMovimentacao);
    },
    adicionarMovimentacaoHistorico(matricula) {
      const novaMovimentacao = {
        aluno_id: matricula.aluno_id,
        matricula_id: matricula.id,
        ano: '',
        serie: '',
        inep: '',
        municipio: '',
        numero_escola: '',
        instituicao_ensino: '',
        estado: '',
        estudante_novo_repetente: '',
        transferencia_ano: false,
        transferencias: '',
        busca_ativa: false,
        observacoes: '',
        situacao_final: '',
        educacao_infantil: false,
        oculta_ano_letivo: false,
      };
      this.movimentacoesHistorico.push(novaMovimentacao);
      this.salvarMovimentacaoAlunoExterno(novaMovimentacao);
    },
    verificarTransferencia(matricula) {
      return matricula.situacao === 'TRANSFERIDO' || matricula.transferencia_ano;
    },
    handleNumeroEscolaInput(index, matricula) {
      if (matricula.numero_escola === '') {
        matricula.numero_escola = index + 1;
      }
    },
    async salvarMovimentacaoMatriculaInterna(matricula) {
      try {
        await this.$services.matriculasService.salvarMovimentacaoMatriculaInterna({
          matricula_id: matricula.id,
          dados: {
            numero_escola: matricula.numero_escola,
            busca_ativa: matricula.busca_ativa,
            estudante_escola: matricula.estudante_escola,
            transferencia_ano: matricula.transferencia_ano,
            transferencias: matricula.transferencias,
            observacoes: matricula.observacoes,
            situacao_estudante: matricula.situacao_estudante
          }
        });
        this.$toast.success("Dados salvos com sucesso");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async salvarHistoricoInterno(matricula) {
      if (!matricula) return;

      try {
        const historicoExistente = await this.$services.matriculasService.existeHistoricoInterno(matricula.matricula_id);

        const usarNotasCircuito = !historicoExistente.existe && !matricula.notas;

        const notasFormatadas = Array.isArray(matricula.notas) && matricula.notas.length > 0
          ? matricula.notas.map((nota) => ({
              disciplina_id: nota.disciplina_id,
              valor: nota.valor
            }))
          : usarNotasCircuito && Array.isArray(matricula.notasCircuito)
            ? matricula.notasCircuito.map((nota) => ({
                disciplina_id: nota.disciplina_id,
                valor: nota.valor
              }))
            : [];

        const disciplinasFormatadas = this.disciplinasInternas.map(disciplina => ({
          disciplina_id: disciplina.disciplina_id,
          valor: disciplina.valor
        }));

        const todasNotas = [...notasFormatadas, ...disciplinasFormatadas];

        const notaInvalida = todasNotas.some(nota => nota.valor > 10);

        if (notaInvalida) {
          this.$toast.danger("Alguma nota é maior que 10. Corrija antes de salvar.");
          return;
        }
        const situacaoFinal = matricula.situacao_estudante || matricula.situacao;
        await this.$services.matriculasService.salvarHistoricoInterno({
          matricula_id: matricula.id,
          dados: {
            educacao_infantil: matricula.educacao_infantil,
            oculta_ano_letivo: matricula.oculta_ano_letivo,
            observacoes: matricula.observacoes,
            situacao_estudante: situacaoFinal,
            notas: todasNotas
          }
        });

        this.$toast.success("Dados salvos com sucesso");
      } catch (error) {
        this.$handleError(error);
      }
    },

    isMovimentacaoValida(movimentacao) {
      return (
        movimentacao.numero_escola &&
        movimentacao.situacao_final
      );
    },
    async salvarMovimentacaoAlunoExterno(movimentacao) {
      if (!this.isMovimentacaoValida(movimentacao)) {
        return;
      }
      try {
        this.submittingForm = true;
        const response = await this.$services.matriculasService.salvarMovimentacaoAlunoExterno(movimentacao);
        if (!movimentacao.id) {
          movimentacao.id = response.data.id;
        }
        this.$toast.success("Dados salvos com sucesso");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async salvarMovimentacaoHistoricoExterno(movimentacao) {
      try {
        if (!movimentacao) {
          return;
        }

        if (!movimentacao.serie) {
          this.$toast.danger("Por favor, preencha o campo Série antes de salvar.");
          return;
        }

        this.submittingForm = true;
        movimentacao.notas = this.formatarNotas(movimentacao);
        const response = await this.$services.matriculasService.salvarMovimentacaoHistoricoExterno(movimentacao);

        if (!movimentacao.id) {
          movimentacao.id = response.data.id;
        }

        this.$toast.success("Dados salvos com sucesso");
            if (Array.isArray(movimentacao.disciplinasExternas)) {
          movimentacao.disciplinasExternas.forEach((disciplina) => {
            disciplina.disciplina = null;
            disciplina.valor = null;
          });
        }


      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
        this.$loaderService.close();
      }
    },

    formatarNotas(movimentacao) {
      if (!movimentacao.disciplinasExternas || movimentacao.disciplinasExternas.length === 0) {
        return movimentacao.notas || [];
      }


      if (!Array.isArray(movimentacao.notas)) {
        movimentacao.notas = [];
      }

      movimentacao.disciplinasExternas.forEach((disciplina) => {
        const existe = movimentacao.notas.some(
          (nota) => nota.disciplina_id === disciplina.disciplina
        );


        if (!existe) {
          movimentacao.notas.push({
            disciplina_id: disciplina.disciplina,
            valor: parseFloat(disciplina.valor) || 0,
          });
        }
      });

      return movimentacao.notas;
    },
    async carregarHistorico(aluno_id) {
      this.carregandoHistorico = true;
      try {
        this.seriesList = await this.$services.seriesService.syncAll();
        this.anosList = await this.$services.anosService.syncAll();
        this.disciplinasList = await this.$services.disciplinasService.syncAll();
        this.franquiasList = await this.$services.franquiasService.syncAll();

        const matriculaHistorico = await this.$services.matriculasService.historico(aluno_id);

        const matriculaGeral = matriculaHistorico.data.matriculas_geral.map(matricula => matricula.id);

        const movimentacoesGeral = await this.$services.matriculasService.movimentacoesMatriculaInterna(matriculaGeral);
        this.historicoMatriculaGeral = matriculaHistorico.data.matriculas_geral.map(matricula => {
          const movimentacao = movimentacoesGeral?.find(m => m.matricula_id === matricula.id);
          return {
            ...matricula,
            numero_escola: movimentacao ? movimentacao.numero_escola : '',
            busca_ativa: movimentacao ? movimentacao.busca_ativa : false,
            estudante_escola: movimentacao ? movimentacao.estudante_escola : '',
            transferencia_ano: movimentacao ? movimentacao.transferencia_ano : false,
            transferencias: movimentacao ? movimentacao.transferencias : '',
            observacoes: movimentacao ? movimentacao.observacoes : '',
            situacao_estudante: movimentacao ? movimentacao.situacao_estudante : '',
          };
        });

        this.notas = matriculaHistorico.data.notas;
        const movimentacoesHistoricoInterno = await this.$services.matriculasService.movimentacoesHistoricoInterno(matriculaGeral);
        this.historicoMatriculaGeral = matriculaHistorico.data.matriculas_geral.map(matricula => {
            const movimentacao = movimentacoesHistoricoInterno?.find(m => m.matricula_id === matricula.id);
            const notasDaMatricula = this.notas.filter(nota => nota.matricula_id === matricula.id);
            const notasDaMovimentacao = movimentacao ? movimentacao.notas : '';
            return {
                ...matricula,
                ...(Array.isArray(notasDaMovimentacao) && notasDaMovimentacao.length === 0 ? {} : { notasCircuito: notasDaMatricula }),
                notas: notasDaMovimentacao,
                observacoes: movimentacao ? movimentacao.observacoes : '',
                situacao_estudante: movimentacao?.situacao_estudante || matricula.situacao,
                educacao_infantil: movimentacao ? movimentacao.educacao_infantil : '',
                oculta_ano_letivo: movimentacao ? movimentacao.oculta_ano_letivo : '',

            };
        });


        const matriculaAnoAtual = matriculaHistorico.data.matricula_ano_atual.map(matricula => matricula.id);
        const movimentacoesMatricula = await this.$services.matriculasService.movimentacoesAlunoExterno(aluno_id, matriculaAnoAtual);
        const movimentacoesFormatadas = movimentacoesMatricula.map(movimentacao => ({
          ...movimentacao,
          numero_escola: movimentacao.numero_escola ? Number(movimentacao.numero_escola) : null, // Converte para número ou deixa `null`
        }));

        const estado = movimentacoesMatricula[0]?.estado;
          if (estado) {
            this.atualizarEstado(estado);
          }
        const movimentacoesHistoricoExterno = await this.$services.matriculasService.movimentacoesHistoricoExterno(aluno_id, matriculaGeral);

        this.movimentacoesExternas = movimentacoesFormatadas;
        this.movimentacoesHistorico = movimentacoesHistoricoExterno;


        const matriculaIds = matriculaHistorico.data.matricula_ano_atual.map(matricula => matricula.id);
        const movimentacaoMatriculaAtual = await this.$services.matriculasService.movimentacoesMatriculaInterna(matriculaIds);
        this.historicoMatriculaAnoAtual = matriculaHistorico.data.matricula_ano_atual.map(matricula => {
          const movimentacao = movimentacaoMatriculaAtual?.find(m => m.matricula_id === matricula.id);
          return {
            ...matricula,
            numero_escola: movimentacao?.numero_escola ? Number(movimentacao.numero_escola) : '',
            busca_ativa: movimentacao ? movimentacao.busca_ativa : false,
            estudante_escola: movimentacao ? movimentacao.estudante_escola : '',
            transferencia_ano: movimentacao ? movimentacao.transferencia_ano : false,
            transferencias: movimentacao ? movimentacao.transferencias : '',
            observacoes: movimentacao ? movimentacao.observacoes : '',
            situacao_estudante: movimentacao ? movimentacao.situacao_estudante : '',
          };
        });

        this.historicoMatriculaAnoAtual.forEach(matricula => {
          if (matricula.situacao === 'TRANSFERIDO') {
            matricula.transferencia_ano = true;
          }
        });

        const response = await this.$services.historicoService.syncAll(aluno_id);
        this.aluno = response.historicoDoSistema[0].aluno;

        let lista = response.historicoExterno.map((historicoItem) => ({
          id: historicoItem.id,
          serie: historicoItem.serie,
          ano: historicoItem.ano,
          escola: historicoItem.escola,
          municipio: historicoItem.municipio,
          flag: true,
        }));

        lista.map((item) => this.historicoList.push(item));

        lista = response.historicoDoSistema.map((historicoItem) => ({
          id: historicoItem.id,
          serie: historicoItem.turma.descricao,
          ano: historicoItem.ano.descricao,
          escola: historicoItem.turma.franquia.descricao,
          municipio: historicoItem.turma.franquia.cidade,
          flag: false,
        }));

        lista.map((item) => this.historicoList.push(item));

      } catch (error) {
        this.$toast.danger("Matrícula do aluno não encontrada!");
      }

      this.carregandoHistorico = false;
    },

    removerDisciplina(matriculaIndex, notaIndex) {
      if (this.historicoMatriculaGeral && Array.isArray(this.historicoMatriculaGeral)) {
        const matricula = this.historicoMatriculaGeral[matriculaIndex];

        if (matricula) {
          if (Array.isArray(matricula.notas) && notaIndex >= 0 && notaIndex < matricula.notas.length) {
            matricula.notas.splice(notaIndex, 1);
          }

          if (Array.isArray(matricula.notasCircuito) && notaIndex >= 0 && notaIndex < matricula.notasCircuito.length) {
            matricula.notasCircuito.splice(notaIndex, 1);
          }
        }
      }
    },
    removerDisciplinaMovimentacaoExterna(notaIndex, movimentacao) {
      if (movimentacao?.disciplinasExternas?.length && notaIndex >= 0 && notaIndex < movimentacao.disciplinasExternas.length) {
        movimentacao.disciplinasExternas.splice(notaIndex, 1);
      }

      if (movimentacao?.notas?.length && notaIndex >= 0 && notaIndex < movimentacao.notas.length) {
        movimentacao.notas.splice(notaIndex, 1);
      }
    },
    removerDisciplinaInterna(dIndex) {
      if (Array.isArray(this.disciplinasInternas) && dIndex >= 0 && dIndex < this.disciplinasInternas.length) {
        this.disciplinasInternas.splice(dIndex, 1);
      }
    },
    removerDisciplinaExterna(movimentacaoIndex, notaIndex) {
      const movimentacao = this.movimentacoesHistorico[movimentacaoIndex];
      if (
        movimentacao &&
        Array.isArray(movimentacao.disciplinasExternas) &&
        notaIndex >= 0 &&
        notaIndex < movimentacao.disciplinasExternas.length
      ) {
        movimentacao.disciplinasExternas.splice(notaIndex, 1);
      }
    },
    obterNotasUnicas(notas) {
      const notasUnicas = {};

      (Array.isArray(notas) ? notas : []).forEach(nota => {
        if (nota.disciplina_id !== null && nota.valor !== null) {

          nota.valor = Math.round(nota.valor * 10) / 10;
          notasUnicas[nota.disciplina_id] = nota;
        }
      });

      return Object.values(notasUnicas);
    },
    async baixarHistorico() {
      const aluno_id = this.$router.currentRoute.params.aluno_id;
      try {
        this.$loaderService.open("Carregando histórico acadêmico...");
        await this.$services.historicoService.baixarHistorico(aluno_id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
  watch: {
    carregandoHistorico(value) {
      if (value && this.historicoList.length === 0) {
        this.$loaderService.open("Carregando histórico");
      } else {
        this.$loaderService.close();
      }
    },
  },
};
</script>

<style>
.highlighted-panel {
  background-color: #f0f0f0;
  font-weight: bold;
  border: 2px solid #dfa70c;
}
</style>
