import municipios from "./municipios";

export const $constants = {
  tiposDeMediacaoDidaticoPedagogica: ["Presencial", "Semipresencial", "Educação a distância – EAD"],
  localDeFuncionamentoDiferenciadoDaTurma: [
    "Sala anexa",
    "Unidade de atendimento socioeducativo",
    "Unidade prisional",
    "A turma não está em local de funcionamento diferenciado",
  ],
  diasDaSemana: ["Domingo", "Segunda", "Terça", "Segunda", "Quarta", "Quinta", "Sexta", "Sábado"],
  tiposDeAtendimento: [
    "Escolarização",
    "Atendimento educacional especializado (AEE)",
    "Atividade complementar",
  ],
  modalidades: [
    "Ensino regular",
    "Educação especial - modalidade substitutiva",
    "Educação de jovens e adultos",
    "Educação profissional",
  ],
  situacoesMatricula: [
    "CURSANDO",
    "APROVADO",
    "INATIVO",
    "REPROVADO",
    "FALECIDO",
    "ABANDONO",
    "TRANSFERIDO",
    "CANCELADO",
    "TROCA DE TURMA",
    "TRANSFERIDO OUTROS",
    "RECEBIDA",
  ],

  // rolesParaProfessores: [
  //   {
  //     id: "2",
  //     name: "professor",
  //   },
  //   {
  //     id: "5",
  //     name: "coordenador",
  //   },
  //   {
  //     id: "7",
  //     name: "direção",
  //   },
  //   {
  //     id: "8",
  //     name: "supervisor",
  //   },
  //   {
  //     id: "11",
  //     name: "professor(PEI)",
  //   },
  // ],

  situacoesUsuarios: [
    {
      descricao: "ATIVO",
      cor: "success",
    },
    {
      descricao: "BLOQUEADO",
      cor: "error",
    },
  ],

  planos: [
    {
      icon: "topic",
      descricao: "Plano bimestral",
      route: "gestoesDeAulas.planoBimestralCreate",
    },
    {
      icon: "integration_instructions",
      descricao: "Plano mensal",
      route: "gestoesDeAulas.newEditplanoMensal",
    },
  ],

  situacoesMatriculaCondition: {
    CURSANDO: false,
    INATIVO: true,
    FALECIDO: true,
    ABANDONO: true,
    TRANSFERIDO: true,
    CANCELADO: true,
    TROCA_DE_TURMA: true,
    TRANSFERIDO_OUTROS: true,
  },

  vinculo: [
    {
      desc: "Efetivo",
      value: 0,
    },
    {
      desc: "Celetista",
      value: 1,
    },
  ],

  bimestres: [
    {
      desc: "1° bimestre",
      value: 0,
    },
    {
      desc: "2° bimestre",
      value: 1,
    },
    {
      desc: "3° bimestre",
      value: 2,
    },
    {
      desc: "4° bimestre",
      value: 3,
    },
  ],

  tipos_pesquisa_aluno: [
    {
      desc: "Pai",
      value: "filiacao2",
    },
    {
      desc: "Mãe",
      value: "filiacao1",
    },
    {
      desc: "Data de nasc",
      value: "data_nascimento",
    },
    {
      desc: "Sexo",
      value: "sexo_id",
    },
    {
      desc: "Cor/Raça",
      value: "cor_ou_raca",
    },
    {
      desc: "Nacionalidade",
      value: "naturalidade",
    },
    {
      desc: "País de nacionalidade",
      value: "nacionalidade",
    },
    {
      desc: "UF de nascimento",
      value: "estadualidade",
    },
  ],
  meses: [
    {
      descricao: "Janeiro",
      id: 1,
    },
    {
      descricao: "Fevereiro",
      id: 2,
    },
    {
      descricao: "Março",
      id: 3,
    },
    {
      descricao: "Abril",
      id: 4,
    },
    {
      descricao: "Maio",
      id: 5,
    },
    {
      descricao: "Junho",
      id: 6,
    },
    {
      descricao: "Julho",
      id: 7,
    },
    {
      descricao: "Agosto",
      id: 8,
    },
    {
      descricao: "Setembro",
      id: 9,
    },
    {
      descricao: "Outubro",
      id: 10,
    },
    {
      descricao: "Novembro",
      id: 11,
    },
    {
      descricao: "Dezembro",
      id: 12,
    },
  ],

  planosForm: [
    {
      descricao: "Unidades tématicas / práticas de linguagem",
      form: "tematica",
      abreviacao: "UNID TEM/PRAT. LING",
      id: 1,
    },
    {
      descricao: "CONTEÚDO | ASSUNTO | OBJETOS DO CONHECIMENTO",
      form: "objeto_conhecimento",
      abreviacao: "OBJ CONH.",
      id: 2,
    },
    {
      descricao: "HABILIDADES EXPLORADAS",
      form: "habilidades",
      abreviacao: "HAB",
      id: 3,
    },
    {
      descricao: "ODS",
      form: "ods",
      abreviacao: "ODS",
    },
    {
      descricao: "METODOLOGIA",
      form: "metodologia",
      abreviacao: "METOD",
      id: 4,
    },
    {
      descricao: "RECURSOS DIDÁTICOS",
      form: "recursos_didaticos",
      abreviacao: "REC DIDAT",
      id: 5,
    },
    {
      descricao: "AVALIAÇÃO DA APRENDIZAGEM",
      form: "avaliacao_aprendizagem",
      abreviacao: "AVAL. APREND",
      id: 6,
    },
    {
      descricao: "REFERÊNCIAS",
      form: "referencias",
      abreviacao: "REF.",
      id: 7,
    },
  ],

  sistemaDeNotas: ["Numerica", "Relatório Infantil", "Relatório Individual"],
  atividadesComplementaresList: [
    {
      codigo: 11002,
      descricao: "Canto coral",
    },
    {
      codigo: 11006,
      descricao: "Banda",
    },
    {
      codigo: 11011,
      descricao: "Iniciação Musical",
    },
    {
      codigo: 12003,
      descricao: "Desenho",
    },
    {
      codigo: 12004,
      descricao: "Escultura e Cerâmica",
    },
    {
      codigo: 12005,
      descricao: "Grafite",
    },
    {
      codigo: 12007,
      descricao: "Pintura",
    },
    {
      codigo: 13001,
      descricao: "Cineclube",
    },
    {
      codigo: 14001,
      descricao: "Teatro",
    },
    {
      codigo: 14002,
      descricao: "Danças",
    },
    {
      codigo: 14004,
      descricao: "Práticas Circenses",
    },
    {
      codigo: 15001,
      descricao: "Capoeira",
    },
    {
      codigo: 15002,
      descricao: "Artesanato",
    },
    {
      codigo: 15003,
      descricao: "Brinquedos",
    },
    {
      codigo: 15004,
      descricao: "Contos",
    },
    {
      codigo: 16001,
      descricao: "Educação Patrimonial",
    },
    {
      codigo: 17004,
      descricao: "Leitura",
    },
    {
      codigo: 17002,
      descricao: "Línguas Estrangeiras",
    },
    {
      codigo: 19999,
      descricao: "Outra categoria de Cultura, Artes e Educação Patrimonial",
    },
    {
      codigo: 21001,
      descricao: "Recreação (Brinquedoteca e Jogos)",
    },
    {
      codigo: 22007,
      descricao: "Yoga",
    },
    {
      codigo: 22009,
      descricao: "Tênis de campo",
    },
    {
      codigo: 22011,
      descricao: "Atletismo",
    },
    {
      codigo: 22012,
      descricao: "Badminton",
    },
    {
      codigo: 22014,
      descricao: "Basquete",
    },
    {
      codigo: 22015,
      descricao: "Ciclismo",
    },
    {
      codigo: 22018,
      descricao: "Futebol",
    },
    {
      codigo: 22019,
      descricao: "Futsal",
    },
    {
      codigo: 22020,
      descricao: "Ginástica(rítmica, artística, acrobática)",
    },
    {
      codigo: 22021,
      descricao: "Handebol",
    },
    {
      codigo: 22022,
      descricao: "Judô",
    },
    {
      codigo: 22023,
      descricao: "Karatê",
    },
    {
      codigo: 22024,
      descricao: "Luta Olímpica",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 22026,
      descricao: "Taekwondo",
    },
    {
      codigo: 22027,
      descricao: "Tênis de Mesa",
    },
    {
      codigo: 22028,
      descricao: "Voleibol",
    },
    {
      codigo: 22029,
      descricao: "Vôlei de Praia",
    },
    {
      codigo: 22030,
      descricao: "Xadrez Tradicional/xadrez virtual",
    },
    {
      codigo: 29999,
      descricao: "Outra categoria de Esporte e Lazer",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 31002,
      descricao: "Português",
    },
    {
      codigo: 31001,
      descricao: "Matemática",
    },
    {
      codigo: 39999,
      descricao: "Outra categoria de Acompanhamento Pedagógico",
    },
    {
      codigo: 41007,
      descricao: "Educação em Direitos Humanos",
    },
    {
      codigo: 71007,
      descricao: "Promoção da Saúde",
    },
    {
      codigo: 10103,
      descricao: "Iniciação Científica",
    },
    {
      codigo: 13301,
      descricao: "Educação Ambiental e Desenvolvimento Sustentável",
    },
    {
      codigo: 13108,
      descricao: "Economia Solidária e Criativa/Educação Econômica (Educação Financeira e Fiscal)",
    },
    {
      codigo: 14101,
      descricao: "Fotografia",
    },
    {
      codigo: 14102,
      descricao: "História em Quadrinhos",
    },
    {
      codigo: 14103,
      descricao: "Jornal Escolar",
    },
    {
      codigo: 14104,
      descricao: "Rádio Escolar",
    },
    {
      codigo: 22025,
      descricao: "Vídeo",
    },
    {
      codigo: 14201,
      descricao: "Robótica Educacional",
    },
    {
      codigo: 14202,
      descricao: "Tecnologias Educacionais",
    },
    {
      codigo: 14203,
      descricao: "Ambientes de Redes Sociais",
    },
    {
      codigo: 14999,
      descricao: "Outra Categoria de Comunicação, Uso de Mídias e Cultura Digital e Tecnológica",
    },
    {
      codigo: 15101,
      descricao: "Memória e História das Comunidades Tradicionais",
    },
  ],
  sexoList: [
    {
      id: 1,
      descricao: "Masculino",
    },
    {
      id: 2,
      descricao: "Feminino",
    },
  ],
  corOuRacaList: ["Branca", "Preta", "Parda", "Amarela", "Indígena", "Não declarada"],
  nacionalidadeList: [
    "Brasileira",
    "Brasileira – nascido no exterior ou naturalizado",
    "Estrangeira",
  ],
  estadosList: [
    { codigo: "AC", descricao: "Acre" },
    { codigo: "AL", descricao: "Alagoas" },
    { codigo: "AP", descricao: "Amapá" },
    { codigo: "AM", descricao: "Amazonas" },
    { codigo: "BA", descricao: "Bahia" },
    { codigo: "CE", descricao: "Ceará" },
    { codigo: "DF", descricao: "Distrito Federal" },
    { codigo: "ES", descricao: "Espírito Santo" },
    { codigo: "GO", descricao: "Goías" },
    { codigo: "MA", descricao: "Maranhão" },
    { codigo: "MT", descricao: "Mato Grosso" },
    { codigo: "MS", descricao: "Mato Grosso do Sul" },
    { codigo: "MG", descricao: "Minas Gerais" },
    { codigo: "PA", descricao: "Pará" },
    { codigo: "PB", descricao: "Paraíba" },
    { codigo: "PR", descricao: "Paraná" },
    { codigo: "PE", descricao: "Pernambuco" },
    { codigo: "PI", descricao: "Piauí" },
    { codigo: "RJ", descricao: "Rio de Janeiro" },
    { codigo: "RN", descricao: "Rio Grande do Norte" },
    { codigo: "RS", descricao: "Rio Grande do Sul" },
    { codigo: "RO", descricao: "Rondônia" },
    { codigo: "RR", descricao: "Roraíma" },
    { codigo: "SC", descricao: "Santa Catarina" },
    { codigo: "SP", descricao: "São Paulo" },
    { codigo: "SE", descricao: "Sergipe" },
    { codigo: "TO", descricao: "Tocantins" },
  ],
  municipios,
  tipoDeDeficienciaList: [
    "Baixa visão",
    "Cegueira",
    "Deficiência auditiva",
    "Deficiência física",
    "Deficiência intelectual",
    "Deficiência Múltipla",
    "Epilepsia",
    "Síndrome de Down",
    "Surdez",
    "Surdocegueira",
    "Transtorno do espectro autista",
  ],
  tipoDeTranstornoDoEspectroAutistaList: ["Transtorno do espectro autista"],
  tipoDeAltasHabilidadesList: ["Altas habilidades/Superdotação"],
  recursosSaebList: [
    "Auxílio ledor",
    "Auxílio transcrição",
    "Guia-intérprete",
    "Tradutor-intérprete de Libras",
    "Leitura labial",
    "Prova ampliada (Fonte 18)",
    "Prova superampliada (Fonte 24)",
    "CD com áudio para deficiente visual",
    "Prova de Língua Portuguesa como Segunda Língua para surdos e deficientes auditivos",
    "Prova em Video Libras",
    "Material didático e prova em Braille",
    "Nenhum",
  ],
  zonaResidenciaList: ["Urbana", "Rural"],
  localizacaoDiferenciadaDeResidenciaList: [
    "Não está em área de localização diferenciada",
    "Área onde se localiza comunidade remanescente de quilombos",
    "Terra indígena",
    "Área de assentamento",
  ],
  rolesParaProfessores: [
    {
      id: "2",
      name: "professor",
    },
    {
      id: "5",
      name: "coordenador",
    },
    {
      id: "7",
      name: "direção",
    },
    {
      id: "8",
      name: "supervisor",
    },
  ],

  rolesParaGestores: [
    {
      id: "1",
      name: "admin",
    },
    {
      id: "4",
      name: "secretario",
    },
    {
      id: "6",
      name: "super-admin",
    },
    {
      id: "9",
      name: "tec-admin",
    },
    {
      id: "7",
      name: "direção",
    },
    {
      id: "5",
      name: "coordenador",
    },
  ],

  tiposDeAulas: [
    "Aula Remota",
    "Aula Normal",
    "Reposição",
    "Aula Extra",
    "Substituição",
    "Aula Antecipada",
    "Atividade Extra-classe",
    "Recuperação",
  ],
  situacoesAulas: [
    {
      descricao: "Aula confirmada",
      cor: "success",
    },
    {
      descricao: "Aguardando confirmação",
      cor: "blue-grey lighten-4",
    },
    {
      descricao: "Aula rejeitada por falta",
      cor: "error",
    },
    {
      descricao: "Aula inválida",
      cor: "brown lighten-3",
    },
    {
      descricao: "Aula em conflito",
      cor: "warning",
    },
  ],

  situacoesPlanos: [
    {
      id: 1,
      descricao: "Plano Confirmado",
      cor: "green",
    },
    {
      id: 2,
      descricao: "Aguardando confirmação",
      cor: "orange",
    },
    {
      id: 3,
      descricao: "Plano Inválido",
      cor: "red",
    },
  ],

  situacoesAulasTabs: [
    {
      descricao: "Confirmadas",
      cor: "green lighten-4",
      tipo: "Aula confirmada",
    },
    {
      descricao: "Aguardando",
      cor: "blue-grey lighten-4",
      tipo: "Aguardando confirmação",
    },
    {
      descricao: "Rejeitadas",
      cor: "red lighten-4",
      tipo: "Aula rejeitada por falta",
    },
    {
      descricao: "Inválidas",
      cor: "brown lighten-5",
      tipo: "Aula inválida",
    },
    {
      descricao: "Conflitadas",
      cor: "amber lighten-4",
      tipo: "Aula em conflito",
    },
  ],
  situacoesAulasCor: {
    "Aula confirmada": "green lighten-2",
    "Aguardando confirmação": "blue-grey lighten-2",
    "Aula rejeitada por falta": "red lighten-2",
    "Aula inválida": "brown lighten-3",
    "Aula em conflito": "amber lighten-2",
  },
  subEtapaTipoCampos: [
    {
      descricao: "Valor (Inserir) - Número",
      slug: "valor",
    },
    {
      descricao: "Valor (Inserir) - Texto",
      slug: "valor_texto",
    },
    {
      descricao: "Resultado em número",
      slug: "resultado_em_lote",
    },
    {
      descricao: "Resultado em Texto",
      slug: "resultado_em_texto",
    },
  ],
  situacaoAutorizacao: ["PENDENTE", "RECUSADO", "APROVADO"],
  cores: [
    {
      descricao: "Preto",
      codigo: "#000",
    },
    {
      descricao: "Vermelho",
      codigo: "red",
    },
    {
      descricao: "Azul",
      codigo: "blue",
    },
    {
      descricao: "Amarelo",
      codigo: "yellow",
    },
    {
      descricao: "Verde",
      codigo: "green",
    },
  ],
  rotinasTurma: [
    {
      id: 1,
      name: "1º Momento :",
      children: [
        {
          id: 2,
          pai: 1,
          name: "Matutino",
        },
        {
          id: 3,
          pai: 1,
          name: "Vespertino",
        },
        {
          id: 4,
          pai: 1,
          name: "Noturno",
        },
      ],
    },
    {
      id: 5,
      name: "2º Momento :",
      children: [
        {
          id: 6,
          pai: 5,
          name: "Matutino",
        },
        {
          id: 7,
          pai: 5,
          name: "Vespertino",
        },
        {
          id: 8,
          pai: 5,
          name: "Noturno",
        },
      ],
    },
    {
      id: 9,
      name: "3º Momento :",
      children: [
        {
          id: 10,
          pai: 9,
          name: "Matutino",
        },
        {
          id: 11,
          pai: 9,
          name: "Vespertino",
        },
        {
          id: 12,
          pai: 9,
          name: "Noturno",
        },
      ],
    },
    {
      id: 13,
      name: "4º Momento :",
      children: [
        {
          id: 14,
          pai: 13,
          name: "Matutino",
        },
        {
          id: 15,
          pai: 13,
          name: "Vespertino",
        },
        {
          id: 16,
          pai: 13,
          name: "Noturno",
        },
      ],
    },
    {
      id: 17,
      name: "5º Momento :",
      children: [
        {
          id: 18,
          pai: 17,
          name: "Matutino",
        },
        {
          id: 19,
          pai: 17,
          name: "Vespertino",
        },
        {
          id: 20,
          pai: 17,
          name: "Noturno",
        },
      ],
    },
    {
      id: 21,
      name: "6º Momento :",
      children: [
        {
          id: 22,
          pai: 21,
          name: "Matutino",
        },
        {
          id: 23,
          pai: 21,
          name: "Vespertino",
        },
        {
          id: 24,
          pai: 21,
          name: "Noturno",
        },
      ],
    },
    {
      id: 25,
      name: "7º Momento :",
      children: [
        {
          id: 26,
          pai: 25,
          name: "Matutino",
        },
        {
          id: 27,
          pai: 25,
          name: "Vespertino",
        },
        {
          id: 28,
          pai: 25,
          name: "Noturno",
        },
      ],
    },
    {
      id: 29,
      name: "8º Momento :",
      children: [
        {
          id: 30,
          pai: 29,
          name: "Matutino",
        },
        {
          id: 31,
          pai: 29,
          name: "Vespertino",
        },
        {
          id: 32,
          pai: 29,
          name: "Noturno",
        },
      ],
    },
    {
      id: 33,
      name: "9º Momento :",
      children: [
        {
          id: 34,
          pai: 33,
          name: "Matutino",
        },
        {
          id: 35,
          pai: 33,
          name: "Vespertino",
        },
        {
          id: 36,
          pai: 33,
          name: "Noturno",
        },
      ],
    },
    {
      id: 37,
      name: "10º Momento :",
      children: [
        {
          id: 38,
          pai: 37,
          name: "Matutino",
        },
        {
          id: 39,
          pai: 37,
          name: "Vespertino",
        },
        {
          id: 40,
          pai: 37,
          name: "Noturno",
        },
      ],
    },
    {
      id: 41,
      name: "11º Momento :",
      children: [
        {
          id: 42,
          pai: 41,
          name: "Matutino",
        },
        {
          id: 43,
          pai: 41,
          name: "Vespertino",
        },
        {
          id: 44,
          pai: 41,
          name: "Noturno",
        },
      ],
    },
  ],
  seriesFinaisFundamentalIds: [33, 36, 35, 37, 44],
  optionsInformativo: [
    { title: "Restringir a um grupo" },
    { title: "Definir data para mostrar" },
    { title: "Definir data para encerrar" },
  ],
  numeroEscola: [
    {
      descricao: "1ª escola",
      id: 1,
    },
    {
      descricao: "2ª escola",
      id: 2,
    },
    {
      descricao: "3ª escola",
      id: 3,
    },
    {
      descricao: "4ª escola",
      id: 4,
    },
    {
      descricao: "5ª escola",
      id: 5,
    },
    {
      descricao: "6ª escola",
      id: 6,
    },
    {
      descricao: "7ª escola",
      id: 7,
    },
    {
      descricao: "8ª escola",
      id: 8,
    },
    {
      descricao: "9ª escola",
      id: 9,
    },
    {
      descricao: "10ª escola",
      id: 10,
    },
  ],
};

export default (vue) => {
  vue.prototype.$constants = $constants;
};
