<template>
  <span class="font-weight-bold">
    {{ !valueIsEmpty ? Math.floor(valueAsNumberOrNull * 10) / 10 : "- - -" }}
    <!-- {{ !valueIsEmpty ? valueAsNumberOrNull : "- - -" }} -->
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    mediaMinima: {
      type: [Number],
      default: 7,
    },
    max: {
      type: Number,
      default: 10,
    },
  },
  watch: {
    value(val) {
      if (val > this.max) {
        this.$emit("change", parseFloat(this.max, 10));
      }
    },
  },
  created() {
    if (this.value > this.max) {
      this.$emit("change", parseFloat(this.max, 10));
    }
  },
  computed: {
    valueAsNumberOrNull() {
      // return !this.valueIsEmpty ? parseFloat(this.value, 10).toFixed(1) : null;
      return !this.valueIsEmpty ? parseFloat(this.value).toFixed(2) : null;
    },
    valueIsEmpty() {
      if (!this.value) {
        return "- - -";
      }
      // eslint-disable-next-line no-restricted-globals
      return this.value === null || this.value === undefined || isNaN(this.value);
    },
  },
};
</script>
