<template>
  <main-template>
    <v-row>
      <v-col class="d-flex">
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'home' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Aulas Aguardando Confirmação
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="aulasList"
          :loading="carregandoAulas"
          :items-per-page="15"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="aula.estaDeletado ? ['deleted'] : []"
                v-for="(aula, i) in items"
                :key="aula.id"
              >
                <td>{{ aula.id }}</td>
                <td>{{ aula.data_formatada }}</td>
                <td>
                  <e-situacao-aula :aula="aula" :i="i" @alterarSituacao="alterarSituacao" />
                </td>
                <td><e-tipo-aula-snippet :aula="aula" /></td>
                <td class="font-weight-medium">
                  {{ aula.turma.descricao ? aula.turma.descricao : "Carregando..." }}
                  <p class="text-caption font-weight-light my-0">
                    {{
                      aula.turma.franquia.descricao
                        ? aula.turma.franquia.descricao
                        : "Carregando..."
                    }}
                  </p>
                </td>
                <td>{{ aula.horario_inicial | hour }}</td>
                <td>
                  {{ aula.horario_final | hour }}
                </td>
                <td>
                  <v-tooltip v-if="!aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => visualizar(aula)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Aula </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="loadData"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
    <e-modal-activator-aula
      :dialog="dialog"
      :aula="aula"
      @dialogChange="dialog = $event"
    ></e-modal-activator-aula>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Aula from "@/Models/Aula";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      dataTableFilter,
      aulasList: [],
      dialog: false,
      aula: {},
      carregandoAulas: true,
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Data", value: "data_formatada" },
          { text: "Situação", value: "situacao" },
          { text: "Tipo", value: "tipo" },
          { text: "Turma", value: "turma" },
          { text: "Início", value: "horario_inicial" },
          { text: "Fim", value: "horario_final" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.carregandoAulas = true;
    this.$loaderService.open("Carregando Aulas");
    this.loadData(this.pageNumber);
    this.$loaderService.close();
    this.carregandoAulas = false;
  },
  watch: {
    async search(val) {
      if (val.length > 2) {
        await this.loadData(0, val);
      }
      if (!val) {
        await this.loadData(0);
      }
    },
  },
  computed: {
    ...mapGetters("GestoesDeAulas", ["gestoesDeAulas"]),
  },
  methods: {
    ...mapActions("GestoesDeAulas", ["loadGestoesDeAulas"]),
    editarAula(aula) {
      this.$router.push({
        name: "aulas.edit",
        params: { aula_id: aula.id },
      });
    },
    alterarSituacao(Objeto) {
      this.$set(this.aulasList, Objeto.i, Objeto.aulaAtualizada);
    },
    visualizar(aula) {
      this.dialog = true;
      this.aula = aula;
    },
    async loadData(pageNumber, query = null) {
      // await this.loadGestoesDeAulas();
      this.carregandoAulas = true;
      const response = await this.$services.aulasService.listarAulasAguardandoConfirmacaoPaginate(
        pageNumber,
        query
      );
      this.aulasList = response.data.map((aula) => new Aula(aula));
      this.paginate = response;
      this.carregandoAulas = false;
    },
    async deletarAula(aula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Aula");
              try {
                await this.$services.aulasService.deletar(aula);
                this.aulasList = this.aulasList.filter((item) => item.id !== aula.id);
                this.$toast.success("Aula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
    recuperarAula(aula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Aula");
              try {
                await this.$services.aulasService.recuperarAula(aula);
                this.$toast.success("Aula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
