<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Agendas
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn
            color="success"
            :to="{
              name: 'agenda.create',
            }"
            class="mr-2"
            >Criar agenda</v-btn
          >
          <v-spacer />
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="agendas"
          :loading="carregandoAgendas"
          :items-per-page="15"
          :search="search"
          no-data-text="Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="agenda.estaDeletado ? ['deleted'] : []"
                v-for="agenda of items"
                :key="agenda.id"
              >
                <td>{{ agenda.id }}</td>
                <td>
                  <span class="d-inline-block text-truncate" style="max-width: 150px">
                    {{ agenda.titulo ? agenda.titulo : "- - -" }}
                  </span>
                </td>
                <td>
                  <span class="d-inline-block text-truncate" style="max-width: 150px">
                    {{ agenda.conteudo ? agenda.conteudo : "- - -" }}
                  </span>
                </td>
                <td>{{ agenda.turma ? agenda.turma.descricao : "- - -" }}</td>
                <td>{{ agenda.data ? agenda.data_formatada : "- - -" }}</td>
                <td>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => enableSubmit(agenda.id)"
                      >
                        <span class="material-icons"> notifications </span>
                      </v-btn>
                    </template>
                    <span>Notificar</span>
                  </v-tooltip>
                  <v-tooltip v-if="!agenda.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="verAgenda(agenda.id, agenda)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver agenda</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'agenda.edit',
                          params: { agenda_id: agenda.id },
                        }"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => destroy(agenda.id)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <e-modal-confirm
          :confirmDialog="confirmDialog"
          :objectModal="objectModal"
          @changeModalConfirm="enableSubmit"
          @changeActionResponse="notificar"
        >
        </e-modal-confirm>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="dialog" width="374">
        <v-card v-if="!agenda" class="mx-auto" max-width="374">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader class="mx-auto" max-width="374" type="card"></v-skeleton-loader>
          </v-sheet>
        </v-card>

        <v-card v-else class="mx-auto" max-width="374">
          <v-img :src="url_image_agenda" height="200px"></v-img>

          <v-card-title> {{ agenda ? agenda.titulo : "- - -" }} </v-card-title>

          <v-card-subtitle> {{ agenda ? agenda.conteudo : "- - -" }} </v-card-subtitle>

          <v-card-actions>
            <v-btn @click="dialog = false" color="orange lighten-2" text> Fechar </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Agenda from "@/Models/Agenda";

export default {
  data() {
    return {
      dialog: false,
      carregandoAgendas: false,
      dataTableFilter,
      confirmDialog: false,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      agendas: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Título", value: "titulo" },
          { text: "Atividade de casa/sala", value: "conteudo" },
          { text: "Turma", value: "turma" },
          { text: "Data", value: "data" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
      url_image_agenda: "",
      agenda: null,
      agenda_id: null,
      objectModal: {
        title: "Atenção",
        message: "Tem certeza que deseja enviar essa notificação?",
      },
    };
  },
  watch: {
    async search(val) {
      if (val.length > 2) {
        await this.loadData(0, val);
      }
      if (!val) {
        await this.loadData(0);
      }
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    enableSubmit(agenda_id) {
      this.confirmDialog = !this.confirmDialog;
      this.agenda_id = agenda_id;
    },
    async loadData() {
      try {
        const response = await this.$services.agendasService.syncAll();
        this.agendas = response.map((agenda) => new Agenda(agenda));
        this.agendas = response;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async notificar() {
      this.$loaderService.open("Enviando notificação...");
      try {
        await this.$services.agendasService.notificar(this.agenda_id);
        this.loadData(this.pageNumber);
        this.confirmDialog = !this.confirmDialog;
        this.$toast.success("Notificação enviada com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async destroy(agenda_id) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar essa agenda?",
        confirmationCode: agenda_id,
        confirmationMessage: `Por favor, digite <strong>${agenda_id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a agenda");
              try {
                await this.$services.agendasService.destroy(agenda_id);
                this.loadData(this.pageNumber);
                this.$toast.success("Agenda deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async verAgenda(agenda_id, agenda) {
      this.dialog = !this.dialog;
      this.$forceUpdate();
      this.url_image_agenda = await this.$services.imageService.downloadImage(
        `notifiq/agendas/download-image/${agenda_id}`
      );
      this.agenda = agenda;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
    this.loadData(this.pageNumber);
  },
};
</script>

<style></style>
