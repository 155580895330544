<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Matriculas</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <div class="d-flex">
            <v-btn color="success" :disabled="estaBloqueado" @click="criarMatricula" class="mr-2"
              >Criar Matricula</v-btn
            >
            <!-- <v-btn
              color="error"
              v-if="roleName === 'super-admin'"
              @click="toggleBloqueioMatriculas"
              class="mr-2"
              >{{ estaBloqueado ? "Desbloquear Matrículas" : "Bloquear Matrículas" }}</v-btn
            > -->
          </div>
          <div>
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </div>
        <v-alert v-if="estaBloqueado" border="left" dense type="error" colored-border elevation="2">
          Está <strong>bloqueado</strong> a criação de novas matrículas.
        </v-alert>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="matriculasList"
          :loading="carregandoMatriculas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="matricula.estaDeletado ? ['deleted'] : []"
                v-for="matricula of items"
                :key="matricula.id"
              >
                <td>{{ matricula.id }}</td>
                <th>
                  {{ matricula.aluno.codigo ? matricula.aluno.codigo : "- - -" }}
                </th>
                <td>
                  {{ matricula.aluno.nomecompleto }}
                  <v-tooltip
                    v-if="
                      matricula.aprovado &&
                      $constants.seriesFinaisFundamentalIds.includes(matricula.serie.id)
                    "
                    color="green"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => certificadoConclusao(matricula)"
                      >
                        <v-icon small color="white">fa fa-certificate</v-icon>
                      </v-btn>
                    </template>
                    <span>Certificado disponível</span>
                  </v-tooltip>
                </td>
                <td class="text-left">
                  {{ matricula.serie ? matricula.serie.descricao : "- - -" }}
                </td>
                <td class="text-left">
                  {{ matricula.turma ? matricula.turma.descricao : "[NÃO INFORMADO]" }}
                </td>
                <td>
                  <div class="text-left">
                    <v-menu :close-on-content-click="false" :nudge-width="100" top offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="blue" class="white--text" v-bind="attrs" v-on="on">
                          AÇÕES
                        </v-btn>
                      </template>

                      <v-card class="mx-auto" max-width="300">
                        <v-list-item>
                          <v-list-item-avatar color="primary">
                            <v-list-item-title style="color: #fff">{{
                              matricula.aluno.nomecompleto
                                ? matricula.aluno.nomecompleto.substring(0, 1).toUpperCase()
                                : "- - -"
                            }}</v-list-item-title>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              matricula.aluno.nomecompleto ? matricula.aluno.nomecompleto : "- - -"
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >{{ matricula.turma.descricao ? matricula.turma.descricao : "- - -" }}
                              /
                              {{ matricula.aluno.codigo ? matricula.aluno.codigo : "- - -" }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                          <v-list-item @click="() => declaracaoCurso(matricula)">
                            <v-list-item-icon>
                              <v-icon small>fa fa-file-pdf</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Declaração de curso</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                        <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                          <v-list-item @click="() => declaracaoConclusao(matricula)">
                            <v-list-item-icon>
                              <v-icon small>fa fa-file-pdf</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Declaração de conclusão</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>

                        <v-list-item-group
                          color="primary"
                          v-if="
                            matricula.aprovado &&
                            seriesFinaisFundamentalIds.includes(matricula.serie.id)
                          "
                        >
                          <v-list-item @click="() => certificadoConclusao(matricula)">
                            <v-list-item-icon>
                              <v-icon small class="mt-2">fa fa-certificate</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Certificado de conclusão <br />do ensino
                                Fundamental</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>

                        <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                          <v-list-item @click="() => fichaDeMatricula(matricula)">
                            <v-list-item-icon>
                              <v-icon small>fa fa-file-pdf</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Ficha De Matricula</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>

                        <v-list>
                          <!-- v-if="!matricula.estaDeletado && $can(['matriculas.editarRoot'])" -->
                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => editarMatricula(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editar matricula</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-list-item-group v-if="matricula.estaDeletado" color="primary">
                          <v-list-item @click="() => recuperarMatricula(matricula)">
                            <v-list-item-icon>
                              <v-icon small>fa fa-sync</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Recuperar matricula</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                        <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                          <v-list-item @click="() => deletarMatricula(matricula)">
                            <v-list-item-icon>
                              <v-icon small>fa fa-trash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Apagar matricula</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import Matricula from "@/Models/Matricula";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      roleName: "",
      userId: "",
      estaBloqueado: false,
      carregandoMatriculas: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      matriculasList: [],
      seriesFinaisFundamentalIds: this.$constants.seriesFinaisFundamentalIds,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Aluno", value: "nomeAluno" },
          { text: "Serie", value: "serieMatricula" },
          { text: "Turma", value: "nomeTurma" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  // created() {
  //   this.verificarEstadoBloqueio();
  // },
  mounted() {
    this.getUserAuth();
    this.listarMatriculas(this.pageNumber);
    this.verificarBloqueio();
  },
  watch: {
    carregandoMatriculas(value) {
      if (value && this.matriculasList.length === 0) {
        this.$loaderService.open("Carregando Matriculas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 3) {
        await this.procurarMatricula(0, val);
      }
      if (!val) {
        await this.procurarMatricula(0);
      }
    },
  },
  methods: {
    async getUserAuth() {
      try {
        const currentUser = await this.$services.authService.currentUser();
        this.roleName = currentUser.roles[0].name;
        this.userId = currentUser.id;
        // console.log(this.userId);
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    },
    async procurarMatricula() {
      try {
        const response = await this.$services.matriculasService.syncAll();
        this.matriculasList = response.map((matricula) => new Matricula(matricula));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async listarMatriculas() {
      this.carregandoMatriculas = true;
      this.$loaderService.open("Carregando Matriculas");
      try {
        const response = await this.$services.matriculasService.syncAll();
        this.matriculasList = response.map((matricula) => new Matricula(matricula)); // response já contém os dados
        this.paginate = response;
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }
      this.carregandoMatriculas = false;
      this.$loaderService.close();
    },

    criarMatricula() {
      this.$router.push({ name: "matriculas.create" });
    },
    async verificarBloqueio() {
      try {
        const response = await this.$services.matriculasService.verificarEstadoBloqueio();
        // console.log(response);
        this.estaBloqueado = response.bloqueado;
        // console.log("verificarEstadoBloqueio");
      } catch (error) {
        this.$handleError(error);
      }
    },

    async toggleBloqueioMatriculas() {
      try {
        const dataBloqueio = new Date();
        const dataFormatada = dataBloqueio.toISOString().slice(0, 10);

        const confirmation = await this.$modals.danger({
          title: "Cuidado!",
          message: this.estaBloqueado
            ? "Por favor, confirme que deseja <strong>desbloquear</strong> a criação de novas matrículas."
            : "Por favor, confirme que deseja <strong>bloquear</strong> a criação de novas matrículas.",
          // confirmationCode: "Sim",
          buttons: [
            {
              text: "Cancelar",
              callback: () => {
                this.$modals.close();
              },
              props: {
                color: "warning",
                small: true,
              },
            },
            {
              text: "Confirmar",
              callback: async () => {
                this.$modals.close();
                await this.$services.matriculasService.bloqueioDeMatriculas({
                  data: dataFormatada,
                  bloquear: !this.estaBloqueado,
                  user: this.userId,
                });
                this.estaBloqueado = !this.estaBloqueado;
                this.$toast.success(
                  `Criação de matrículas ${
                    this.estaBloqueado ? "bloqueada" : "desbloqueada"
                  } com sucesso`
                );
              },
              props: {
                color: "error",
                small: true,
              },
            },
          ],
        });

        if (!confirmation) {
          return;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    editarMatricula(matricula) {
      this.$router.push({
        name: "matriculas.edit",
        params: { matricula_id: matricula.id },
      });
      // return matricula;
    },
    async declaracaoCurso(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.declaracaoCurso(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async fichaDeMatricula(matricula) {
      this.$loaderService.open("Baixando ficha de matricula");
      try {
        await this.$services.relatoriosService.fichaDeMatricula(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async declaracaoConclusao(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.declaracaoConclusao(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async deletarMatricula(matricula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta matricula?",
        confirmationCode: matricula.id,
        confirmationMessage: `Por favor, digite <strong>${matricula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Matricula");
              try {
                await this.$services.matriculasService.deletarMatricula(matricula);
                this.$toast.success("Matricula deletada com sucesso");
                await this.listarMatriculas();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarMatricula(matricula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta matricula?",
        confirmationCode: matricula.id,
        confirmationMessage: `Por favor, digite <strong>${matricula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Matricula");
              try {
                await this.$services.matriculasService.recuperarMatricula(matricula);
                this.$toast.success("Matricula recuperada com sucesso");
                await this.listarMatriculas();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async certificadoConclusao(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.certificadoConclusao(matricula.id);
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
  },
};
</script>

<style></style>
